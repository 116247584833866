/*******************************************************
 * Name of the script: App.js
 * Project : Sindhu Sagar Academy
 * Owner : Sindhu Sagar Academy
 * Owner URL : https://sindhusagaracademy.co.in/
 * Author: Blaze Technologies
 * Author Url : https://blazetechnologies.in/
 * Date: May 15, 2023
 * Description: This script Is Entry For App.
 *******************************************************/


import logo from './logo.svg';
import './App.css';
import Appstart from './Appstart';

function App() {
  return (
    <Appstart/>
  );
}

export default App;
