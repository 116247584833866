import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Chairman(props) {
  return (
    <>
        <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>MR.NARAYAN LEKHRAJ PUNJABI</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>

<Container className='pt-5'>
      <Row>
        <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
        {/* <b>PRESIDENT</b><br/> */}
        {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/admin/images/tSSPnYtGpZkxixdE1Y9xcHZC6t5kgmZTmWooIvKN.JPG' /> */}
        <img width={"50%"} src='https://sindhusagaracademy.co.in/files/banners/PANJABI%20SIR.jpg' />
    <h2>MR.NARAYAN LEKHRAJ PUNJABI</h2>
    <b>PRESIDENT</b><br/>
        </Col>
        <Col md={6}>
          <ul style={{textAlign:'justify', listStyleType:'none'}}>
            <li style={{paddingBottom:5}}>This is my fourth tenure when I have been chosen as the president of this Mandal's, I am very thankful to our entire management team of Nasik Sindhi Shikshan Mandal's for their guidence and co-operation. I also express my gratitude towards the staff members of {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} who tirelessly keep supporting and co operating the management.. I will be very happy to serve this institution as long as I can to the best of my ability. To be with you all gives me an internal happiness and pleasure because I am connected to this school and surrounding since I was a student.</li> 
            <li style={{paddingBottom:5}}>Running an institution is not one man's cup of tea. It's team work. So let us all come together, put in all our efforts and make it a grand success...</li>  
            <li style={{paddingBottom:5}}>Dear Children do remember these few thoughts they will help you a lot for your future life</li>
            <li style={{paddingBottom:5,fontWeight:"bold"}}>"Relax and enjoy life you'll never live this day again”</li> 
            <li style={{paddingBottom:5,fontWeight:"bold"}}>"Be happy as you are and what you have”</li> 
            <li style={{paddingBottom:5,fontWeight:"bold"}}>"Do something great before you enter the horicon of heaven”</li> 
            <li style={{paddingBottom:5}}>I can say you have come to the best place to get yourself nurturned and educated not only in academics but in multi fold activities that too in a very loving and caring ambience. So, collect all the happy memories for your future and remember to <b>"live for today.”</b></li> 
            <li style={{paddingBottom:5}}>Arise, awake and do not stop untill your goal is achiveed. So, lift up your spirits and say this to yourself everyday</li> 
            <li style={{paddingBottom:5}}>May God grant me the serenity to accept the things I cannot change.</li> 
            <li style={{paddingBottom:5}}>The courage to change the things I can and the wisdom to know the difference"</li> 
            <li style={{paddingBottom:5}}>I believe that children look best when they bring a smile on their dace. So, <b>"always keep smiling"</b>, the bigger the smile,the farther will be the problems</li> 
          </ul>
        </Col>
      </Row>
    </Container>
    </>
    
  
  )
}

export default Chairman