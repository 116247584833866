import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Prospectus() {
  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>Prospectus</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row >
    <Col md={12} className='text-center pt-5 pb-5'>
                <h3>Prospectus</h3>
                <a href="https://sindhusagaracademy.co.in/files/banners/Prospectus%202024_compressed.pdf" target="_blank"  download  class="btn" style={{ backgroundColor: '#630700' , color: 'white'}}>Download</a>
              </Col>
    </Row>
  </Container>
    </>
    
  )
}

export default Prospectus