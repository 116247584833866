
import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { AllCollegeNews, AllNews } from '../../utils/proxy/Authproxy';

function News(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchGalleryData = async () => {
        try {
          if (props.school == true) {
            const response = await AllNews();
            setData(response.data.data)
          } else {
            const response = await AllCollegeNews();
            setData(response.data.data)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
      fetchGalleryData();
    }, []);

    return (
        <Container>
            <Row className='text-center'>
                <Col>
                    <h2><b style={{ color: 'orange' }}>ACADEMIC</b> NEWS</h2>
                </Col>
            </Row>

            <Row>
                {/* Display your data */}
       {data.map((item) => (
        <Col key={item.id} md={3} className="p-2">
        <img width={"100%"} src={`https://admin.sindhusagaracademy.co.in/SindhuAPI/public/uploads/${item.news}`}/>
        </Col>
      ))}
            </Row>
        </Container>
    )
}

export default News