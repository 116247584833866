import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Aboutschool() {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>About School</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>
          {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/admin/images/JFyNAFoD4tl9FZcLF4ciMCDFMNHsvVQPTHQZCmLt.JPG' /> */}
          <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/building.jpg' />
          </Col>
          <Col md={6}>
            <h2>About School</h2>
            <p style={{textAlign:'justify'}}>
              Welcome to SindhuSagar Academy a highly progressive and enterprising school which runs from Nursery to 12th is pledged to provide quality education with great emphasis on traditional values. The school is well managed by a team of dedicated Management members and attempt is made to provide all possible opportunities of the best education and development of an all round personality of the child to meet the challenges of a competitive future.SindhuSagar Academy Established in the year 1949 Nasik Sindhi ShikshanMandal's maintains a reputation for its high academicstandards, personal care and attention to the all round development of talents and personality of each student the school provides a very homely and friendly atmosphere for nurturing the students. Apart from regularacademics the school lays emphasis on the moral, aesthetic and physical growth of each individual, academicactivities are amply backed up by the equally co curricular activities like dramatics. Drawing , painting,storytelling, poetry  recitation etc,. and Cultural Variety programs boost the confidence of the child. The Schoolemploys well-qualified staff to meet the academic and extra-curricular needs of the students. Over the years, theschool has steadily grown in size, in educational scope and in reputation until it is now counted among thebetter-known schools in Nashik.
            </p>
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default Aboutschool