import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import VideoPlayer from '../../components/VideoPlayer';
import Accordion from 'react-bootstrap/Accordion';

function CollegeFaq(props) {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>FAQ's</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>


            {/* <VideoPlayer src="https://sindhusagaracademy.co.in/files/images/about/Designer.mp4" /> */}
            <VideoPlayer src="https://sindhusagaracademy.co.in/files/banners/faq.mp4" />


          </Col>
          <Col md={6} className='center'>
            <h2>Frequently Asked Questions.</h2>
            <p style={{textAlign:'justify'}}>
              Welcome to {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}! Here are some common questions and answers for your convenience. What programs do we offer? We provide a variety of academic programs. How can I apply for admission? The application process involves Offline Form Filling. Are scholarships or financial aid available? Yes, we offer scholarships and financial aid programs. Contact us for more information. How can I reach the College administration? You can contact our administration office at nashiksindhis.mandal@gmail.com. What extracurricular activities are available? We offer a range of activities and clubs to suit different interests. Visit our Activities page for details. Where can I find the academic calendar? The academic calendar is available on our website's Calendar section. How can parents access their child's grades and progress? We provide an online portal for parents to access grades and progress reports. Contact the College for login details. What safety measures are in place? We prioritize student safety with protocols, surveillance, and trained staff. Are transportation services available? Yes, transportation services are available for eligible students. Contact our transportation department for details. Can I visit the campus? Yes, schedule a campus tour by contacting our admissions office. If you have further questions, feel free to reach out to us.
            </p>
          </Col>

        </Row>

        <Row>
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How can I apply ?</Accordion.Header>
                <Accordion.Body>
                  You can call or visit our office which is stated in our contact details
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I Pay Fees in Installment ?</Accordion.Header>
                <Accordion.Body>
                  Yes, You can divide it in Installment
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What is the admission procedure?</Accordion.Header>
                <Accordion.Body>
                Please check admission tab for admission procedure
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>What are my ward’s college timings?</Accordion.Header>
                <Accordion.Body>
                  <p>8:30 AM to 12:30 PM</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>What facilities does your College offer?</Accordion.Header>
                <Accordion.Body>
                  <p>The infrastructure of the College aids and fosters the process of learning -</p>
                 <ul>
                  <li>spacious and ventilated classrooms.</li>
                  <li>Technologically well-equipped classrooms with smart boards and computers which are easily accessible to students with internet browsing.</li>
                  <li>Well-equipped Music hall and Dance hall.</li>
                  <li>Knowledge enriched Resource and Research Centre.</li>
                  <li>Science, Biology, Physics and IT Labs.</li>
                  {/* <li>State-of-the-art Audio-Visual Auditorium.</li> */}
                  <li>Canteen</li>
                  <li>Play area.</li>
                  <li>Multipurpose area for sports</li>
                 </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>What is the intake of college?</Accordion.Header>
                <Accordion.Body>
                  <p>College has intake of 80 students per stream</p>
                 
                </Accordion.Body>
              </Accordion.Item>
            
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CollegeFaq