import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { AllCollegeEvents, AllEvents } from '../../utils/proxy/Authproxy';

function Upcommingevent(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        if (props.school) {
          const response = await AllEvents();
          setData(response.data.data)
        }else{
          const response = await AllCollegeEvents();
          setData(response.data.data)
        }
        
        
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchGalleryData();
  }, []);


  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };



  // Determine items to display per page
  const itemsPerPage = 8;
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    <>
     

      <Container className='pt-5'>

      <Row>
       {/* Display your data */}
       {currentPageData.map((item) => (
        item.status == 'upcomming' ? 
        <Col key={item.id} md={3} className="p-2">
      <img width={"100%"} src={`https://${props.school ? 'admin':'college'}.sindhusagaracademy.co.in/SindhuAPI/public/uploads/${item.news}`}/>
      </Col>
      :""
      ))}

      {/* Render the pagination component */}
      <ReactPaginate
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
      </Row>
    </Container>

    </>
  )
}

export default Upcommingevent