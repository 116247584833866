import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AddCollegeEnquiry, AddEnquiry } from '../../utils/proxy/Authproxy';
import Swal from 'sweetalert2'

function Enquiry(props) {

  const [studentname,setStudentname] = useState("");
  const [cityname,setCityname] = useState("");
  const [mobilenumber,setMobilenumber] = useState("");
  const [email,setEmail] = useState("");
  const [message,setMessage] = useState("");

  const addenquiry = async () => {
    try {
      if (props.school) {
        const response = await AddEnquiry(studentname,cityname,mobilenumber,email,message);
      } else {
        const response = await AddCollegeEnquiry(studentname,cityname,mobilenumber,email,message);
      }
      

      Swal.fire({
        title: 'Success!',
        text: 'Enquiry Has Been Submitted ..!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>Enquiry</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row >
      <Col md={6} className='center'>
      <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/Enquirynow.png' />
      </Col>
      <Col md={6} className='center'>
      <p>Phone No : 0253 2572661</p>

      <p>Email : nashiksindhis.mandal@gmail.com</p>


        <hr/>
        <p className='lead'>Fill The Form Below To Get In Touch !</p>
      <Form>
            <Form.Group className="mb-3">
              <Form.Label>Student Name</Form.Label>
              <Form.Control type="text" onChange={e => setStudentname(e.target.value)}
        defaultValue={studentname} placeholder="Enter Student Name" />
            </Form.Group>
            {/* <Form.Group className="mb-3">
        <Form.Label>Father Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Father Name" />
      </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label>City Name</Form.Label>
              <Form.Control type="text" onChange={e => setCityname(e.target.value)}
        defaultValue={cityname} placeholder="Enter City Name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" onChange={e => setMobilenumber(e.target.value)}
        defaultValue={mobilenumber} placeholder="Enter Mobile Number" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" onChange={e => setEmail(e.target.value)}
        defaultValue={email} placeholder="Enter Email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" onChange={e => setMessage(e.target.value)}
        defaultValue={message} placeholder="Enter Message" />
            </Form.Group>

            <Button variant="primary" onClick={addenquiry} >
        Submit
      </Button>
          </Form>

  
   
      

      </Col>
    </Row>
  </Container>
    </>
  )
}

export default Enquiry