import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Carousel from 'react-bootstrap/Carousel';
import VideoPlayer from '../../components/VideoPlayer';

function Admission(props) {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Admission</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>
         
            {/* <VideoPlayer src="https://sindhusagaracademy.co.in/files/images/about/Designer.mp4" /> */}
            {/* <VideoPlayer  src="https://sindhusagaracademy.co.in/files/images/about/SS%20Reel%20Drawing.mp4"/> */}
            <VideoPlayer  src="https://sindhusagaracademy.co.in/files/banners/admission-reel1.mp4"/>
                      </Col>
          <Col md={6} className='center'>
            <h2>Join {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}</h2>
            <p style={{ textAlign: 'justify' }}>
              Enroll your child in {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} for an exceptional educational journey. Our nurturing environment, experienced educators, and holistic approach ensure academic excellence and character development. With small class sizes and personalized attention, your child will thrive. Visit our website for more information and admission details. Join our vibrant community at {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} today!
            </p>
            {props.school ? <p>
              <a target='_blank' href="/School_Addmission_Form.pdf">Click Here To Download Admission Form</a>
            </p> : ""}
          </Col>

        </Row>

        {props.school ?
          <>
            <Row>
              <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <h4>DOCUMENTS REQUIRED FOR ADMISSION FOR SCHOOL</h4>
                <ul>
                  <li>Birth certificate.</li>
                  <li>Report card of previous school.</li>
                  <li>Leaving certificate of previous School.</li>
                  <li>Address proof.</li>
                  <li>Passport size photos – 3.</li>
                  <li>Caste certificate.</li>
                  <li>Medical certificate mentioning blood group of the child.</li>
                  <li>Aadhar Card No.</li>
                </ul>
              </Col>
              <Col md={6}>
                <img src='/docu.png' />
              </Col>
            </Row>
            <Row>

              <Col md={6}>
                <img src='/interview.png' />
              </Col>
              <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <h4>GENERAL ADMISSION RULES AFTER INTERVIEW</h4>
                <ol>
                  <li>Forms to be filled correctly.</li>
                  <li>Correct and genuine information about the child and parents to be provided during final interview.</li>
                  <li>Birth certificate & TC should be provided during admission.</li>
                  <li>Fees should be paid in installments as per the schedule decided by management. </li>
                </ol>
              </Col>
            </Row>
          </> : ''}

        {!props.school ?
          <Row>
            <Col className=' pt-5' md={6}>
              <h3>DOCUMENTS REQUIRED FOR ADMISSION FOR COLLEGE</h3>
              <ol>
                <li>Caste Certificate (for reserved category only)</li>
                <li>Recent passport size photographs</li>
                <li>Original Marksheet of  S.S.C. Board & leaving certificate of last school attented</li>
                <li>Medical fitness certificate of students from registered medical practitioner</li>
                <li>Migration certificate (if change of Board/State)</li>
                <li>Zerox of Aadhar Card</li>
                <li>Eligibility certificate (if change of Board/State)</li>
              </ol>
            </Col>
            <Col md={6}>
              <img src='/docu.png' />
            </Col>
          </Row> : ""}
      </Container>
      {!props.school ?
        <>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <h3>Stream & Subjects We Provide</h3>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'space-around' }}>
              <Col className='favour' md={5} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                <h2>Science</h2>
                <ul>
                  <li>English</li>
                  <li>Marathi</li>
                  <li>IT</li>
                  <li>Physics</li>
                  <li>Chemistry</li>
                  <li>Biology</li>
                  <li>Geography</li>
                  <li>Maths</li>
                  <li>Environmental Science</li>
                  <li>Physical Education</li>
                </ul>
              </Col>
              <Col className='favour' md={5} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                <h2>Commerce</h2>
                <ul>
                  <li>English</li>
                  <li>Marathi</li>
                  <li>IT</li>
                  <li>Book Keeping & Accountancy</li>
                  <li>Economics</li>
                  <li>Secretarial Practice</li>
                  <li>Organizational Commerce</li>
                  <li>Maths</li>
                  <li>Environmental Science</li>
                  <li>Physical Education</li>
                </ul>
              </Col>
            </Row>

          </Container>
          <hr/>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <h3>XI ADMISSION PROCESS</h3>
              </Col>

            </Row>
            <Row>
              <Col md={12}>
                <h2>Step 1 Admission Confirmation</h2>
                <p>Fill the Online admission confirmation form on https://11thadmission.org.in/</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h2>STEP 2 Admission documentation</h2>
                <Row style={{ justifyContent: 'space-around' }}>
                  <Col className='favour' md={5} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>FOR STATE BOARD</h2>
                    <p>Following list of Document require</p>
                    <ul>
                      <li>Original LC / TC Of X</li>
                      <li>Mark sheet (Xerox )</li>
                      <li>Adhaar Card (Xerox )</li>
                      <li>Student passport photograph (2Nos.)</li>
                      <li>Caste Certificate ( If Applicable, Xerox with self attested )</li>

                    </ul>
                  </Col>
                  <Col className='favour' md={5} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>CBSE , ICSE & OTHER BOARD</h2>
                    <p>Following list of Documents required</p>
                    <ul>
                      <li>Original LC / TC</li>
                      <li>Original Migration</li>
                      <li>Passing Certificate (Xerox )</li>
                      <li>Mark sheet (Xerox )</li>
                      <li>Adhaar Card (Xerox )</li>
                      <li>Student passport photograph (2 Nos.)</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Container>
          <hr/>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <h3>Subjects For Science With I.T</h3>
              </Col>

            </Row>
            <Row>
              <Col md={12}>
                
                <Row style={{ justifyContent: 'space-around' }}>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Compulsory Subject</h2>
                    <ul>
                      <li>English</li>
                      <li>Physics</li>
                      <li>Chemistry</li>
                      <li>Environmental Science</li>
                      <li>Physical Education</li>

                    </ul>
                  </Col>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Group 1 Optional Subject<br/>Choose Any One</h2>
                    <ul>
                      <li>Information Technology (I.T)</li>
                      <li>Marathi</li>
                      
                    </ul>
                  </Col>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Group 2 Optional Subject <br/>Choose Any Two</h2>
                    <ul>
                      <li>Mathematics</li>
                      <li>Biology</li>
                      <li>Geography</li>
                     
                    </ul>
                  </Col>
                  
                </Row>
              </Col>
            </Row>

          </Container>
          <hr/>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <h3>Subjects For Commerce With I.T</h3>
              </Col>

            </Row>
            <Row>
              <Col md={12}>
                
                <Row style={{ justifyContent: 'space-around' }}>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Compulsory Subject</h2>
                    <ul>
                      <li>English</li>
                      <li>Book Keeping And Accountancy</li>
                      <li>Organizational Commerce</li>
                      <li>Economics</li>
                      <li>Environmental Science</li>
                      <li>Physical Education</li>

                    </ul>
                  </Col>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Group 1 Optional Subject<br/>Choose Any One</h2>
                    <ul>
                      <li>Information Technology (I.T)</li>
                      <li>Marathi</li>
                      
                    </ul>
                  </Col>
                  <Col className='favour' md={3} style={{ padding: '20px', backgroundColor: '#630700', margin: '10px' }}>
                    <h2>Group 2 Optional Subject <br/>Choose Any One</h2>
                    <ul>
                      <li>Mathematics</li>
                      <li>Secretarial Practice</li>
                
                     
                    </ul>
                  </Col>
                  
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12} className='text-center pt-5 pb-5'>
                <h3>ADMISSION FORM</h3>
                <a href="https://sindhusagaracademy.co.in/files/banners/Admission-form-college_compressed.pdf" target="_blank" download  class="btn" style={{ backgroundColor: '#630700' , color: 'white'}}>Download</a>
              </Col>

            </Row>

          </Container>
        </>


        : ""}
    </>
  )
}

export default Admission