import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Vicepresident() {
  return (
    <>
        <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>VICE PRESIDENT DESK</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>

    <Container className='pt-5'>
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>VICE PRESIDENT DESK</b><br/> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/images/about/member-placeholder.png' /> */}
      <img width={"50%"} src=' https://sindhusagaracademy.co.in/files/banners/kalani-sir.jpg' />
    <h2>MR.KANAIYALAL KIMATRAM KALANI</h2>
    <b>VICE PRESIDENT DESK</b><br/>
      </Col>
      <Col md={6}>
        <ul style={{textAlign:'justify', listStyleType:'none'}}>
          <li style={{paddingBottom:5}}>The management of Sindhu Sagar Academy English Medium High School And R.K.Kalani JR. College brings together a great learning community. We strive to build independent, responsible, global citizens through a widely accepted curriculum and practices that are founded on learner-centric education. </li>
          <li style={{paddingBottom:5}}>As we open our doors to each new generation of students, expectations run high as we believe that each and every one of them will grow into the stars in our skies. Every day, every year we scale new heights, never standing still.</li>
         
        </ul>
      </Col>
    </Row>
  </Container>
    </>
    
  )
}

export default Vicepresident