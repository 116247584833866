import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Ourmission() {
  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>Our Mission</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row>
      <Col md={6} className='center'>
      {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/schoolmission.jpeg' /> */}
      <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/mission%20image.jpg' />
      </Col>
      <Col md={6} className='center'>
        <h2>Our Mission</h2>
        <p style={{textAlign:'justify'}}>
        Our mission is to develop students with active and creative minds, a sense of understanding and compassion for others, and the courage to act on their beliefs. Our School recognizes that each child is an individual, that all children are creative, that all childrenneed to succeed. Therefore, our School respects the individual needs of children fosters a caring and creative environment, and emphasizes the social, emotional, physical, intellectual development of each child.  We stress the total development of each child, spiritual, moral, intellectual, social, emotional, and physical. A love of nature, a sense of self-worth, responsibility, self confidence&amp; discipline, while having fun are some of the things we inculcate in our children besides book learning.
        </p>
      </Col>
    </Row>
  </Container>
    </>
    
  )
}

export default Ourmission