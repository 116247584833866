import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'

import {  BsMusicNoteList } from 'react-icons/bs'
import { SiJordan } from 'react-icons/si'
import { SiGoogleclassroom } from 'react-icons/si'
import { TbKarate } from 'react-icons/tb'
import { MdBiotech, MdDraw, MdOutlineSportsGymnastics, MdOutlineSportsMartialArts, MdComputer, MdScience, MdOutlineSportsKabaddi} from 'react-icons/md'
import { HiLibrary, HiLightBulb } from 'react-icons/hi'


import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Campusfacilities() {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >Infrastructure</Breadcrumb.Item>
            <Breadcrumb.Item active>Campus Facilities</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6}>
          {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/Campusfacilities.png' /> */}
          <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/campus.jpg' />
          </Col>
          <Col md={6} className='text-center center pt-4'>
            <h5>We offer an academic, sporting, co-curricular and personal development programme that affords students every opportunity to grow in knowledge, self-confidence, skill and understanding. </h5>
          </Col>
        </Row>
        <Row className='pt-3'>
          <Col>
            <Card className='card-facilites'>
            <MdComputer color='black'/>
              <Card.Body className='fac-pad'>IT LAB</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <SiGoogleclassroom color='black' />
              <Card.Body className='fac-pad'>SMART CLASS</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <HiLibrary color='black' />
              <Card.Body className='fac-pad'>LIBRARY</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <MdBiotech color='black' />
              <Card.Body className='fac-pad'>BIOLOGY LAB</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <HiLightBulb color='black' />
              <Card.Body className='fac-pad'>PHYSIC LAB</Card.Body>
            </Card>
          </Col>
          

        </Row>
        <Row className='pt-3'>
        <Col>
            <Card className='card-facilites'>
              <MdOutlineSportsGymnastics color='black' />
              <Card.Body className='fac-pad'>SPORTS</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
            <MdScience />
              <Card.Body className='fac-pad'>CHEMISTRY LAB</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <BsMusicNoteList color='black' />
              <Card.Body className='fac-pad'>MUSIC HALL</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <SiJordan color='black' />
              <Card.Body className='fac-pad'>DANCE HALL</Card.Body>
            </Card>
          </Col>
          {/* <Col>
            <Card className='card-facilites'>
              <MdDraw color='black' />
              <Card.Body className='fac-pad'>DRAWING ROOM</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='card-facilites'>
              <TbKarate color='black' />
              <Card.Body className='fac-pad'>KARATE HALL</Card.Body>
            </Card>
          </Col> */}
          <Col>
            <Card className='card-facilites'>
              <MdOutlineSportsKabaddi/>
              <Card.Body className='fac-pad'>PLAY GORUND</Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>

    <Container className='pt-4 pb-5'>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>DIGITAL/SMART CLASSROOM</Accordion.Header>
        <Accordion.Body style={{textAlign:'justify'}}>
        {/* Each classroom houses a large area and is designed such that every student gets the teacher's maximum individual attention. Well-ventilated Air Conditioned Classrooms offer the right ambience for learning. The bulletin boards in the classroom make learning easier, colourful and interesting. The strength of each class is kept at a manageable level and the comfortable furniture adds to the ambience of it */}
        To create peaceful learning atmosphere and nice ambiance each classroom are specially
designed with huge area, well ventilation & Air conditioned. The comfortable furniture in
each class take care of health of each student and adds value in the ambiance of
classroom. Outmost care has been taken while designing student gets the teacher's
maximum individual attention. The bulletin boards in the classroom make learning easier,
colourful and interesting. The strength of each class is kept manageable and comfortable.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>MUSIC HAll</Accordion.Header>
        <Accordion.Body style={{textAlign:'justify'}}>
          <p>Music is a significant part of every culture, tradition, religion, etc. Feelings are magnified when expressed through music. In collaboration with Music, SindhuSagar Academy English Medium High School has introduced a curriculum that provides children with an opportunity to learn and play musical instruments, and express thoughts in a creative and artistic way.Our curriculum revolves around</p>
          <ul>
            <li>Theory of Music</li>
            <li>History of Music</li>
            <li>Instrument Specialization (Piano, Congo,Drumset,Octapad)</li>
            <li>Vocals (using the Kodaly method)</li>
          </ul>
          <p style={{textAlign:'justify'}}>We have introduced Music History and Music Appreciation wish aids in understanding music intellectually and also exposes the student to different styles and eras ofmusic.This unique curriculum includes:</p>
          <ul>
            <li>Performance skills</li>
            <li>Music history, music theory, and analysis, music appreciation, choral skills, composition skills, and world music. Mainly vocals and percussion instruments</li>
            <li>Our Music curriculum is segregated into</li>
          </ul>
          <p style={{textAlign:'justify'}}>SSA School give Performing Arts a special place at curriculum and co-curricular arena. Performing Arts provide student self-expression and interpersonal skills. At SSA students learn variety of Indian and western instruments. Every student finds expression to his or her rhythm and thereafter, hones these talents from contemporary to classical.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>DANCE HAll</Accordion.Header>
        <Accordion.Body style={{textAlign:'justify'}}>
           <p>To enrich the happiness of children and with the motive that every should be involved in at
least one form of Art SSA is taking huge efforts. Children should be involved in at least one
art form as it contributes to their happiness. We believes that to bring out the hidden skills
of kids, to enhance their creativity, expressions and confidence Dance is the best media .
We designed our curriculum by making dance as integral part of our balance education
program and due to which it will not ignore at any stage. We have introduced an entire
curriculum on kinaesthetic learning to make sure our students get the best chance at
improving their body and mind concentration. Bringing a balance to their lives. The Dance
curriculum for the Secondary classes of Grade V to X takes a step forward to advance
training as per the age group being covered in the curriculum. Each dance form and activity
has been allocated different weeks and broken down into steps in order to cover every
aspect of every form and take out the best of learning that we have to grasp. The Class
structure covers everything from warm up, isolation, footwork etc. to flexibility cool downs.
The similar structure is formed for every dance form. We have taken into account each
element of dance, Body / Action/Space/Energy / Relationships. The last week counts for a
showcase week, where all the efforts are put to presentation in Mega Events. Our Dance
curriculum is unique for each age group and each dance form. We move forward with
proper assessments and a strategize curriculum, always ready to be upgraded with the
latest trends and teaching methods with the help of our highly trained professionals.
Students are given training to learn different forms of dance- Folk, Classical, Semi-Classical,
& Western. Theme base dance team already given many professional performance in
different shows & competitions. To enhance such skills of every student the personality like
Mr Pankaj Gangurde sir is the part of team SSA. He is a Choreographer with a vast
experience of 16 years in the field of Choreography. He was the part of many events like
Yuvamahotsav 2007, Lokbiradari Aurangabad 2014, Lokbiradari 2009, Mahapaurchashak
2004, Ashwamedh 2008, Urja Mohotsav 2008-2011, Marathi Bhasha Din 2012, Kamgar
Kalyan Mahotsav 2014, Mahapaurchashak 2014, Rangbhoomi Din 2015 ,Godautsav 2016-
2018.He has also won many prizes in well-known shows like Durdarshan Tv folk dance,
Rotary club, Nasiksamachar, group choreography winner, Choreography -Dada Saheb Phalke
Chitrapatmahotsav, Marathi Paul Padte Pudhe, live dance act on Azadi 50/60/70. He has
also done many theatre shows like marathibana live show 1000 experiment, super women E
Tv reality show (marathi), Rashtrapati 75 golden mahotsav, delhiheart show, making india
live show, Delhi to Kanyakumari all live theatre dances, Tv Chanal He has also worked in E
Tvmarathi, zee marathi, star pravha, colors marathi award shows marathi album and movies
as a dancer and choreographer.Zindagani, lagir, khokho, Ajintha, Mr. Pankaj also hold some
great achievement like Marathi Chitrapat mahamandal best Choreography puraskar.</p>
          {/* <b>Dance rooms</b> */}
          <p style={{textAlign:'justify'}}>Well-designed Dance Room keeping in view space, aesthetic and acoustic dance rooms as
comfortable space, and dance teachers to help our students express their feelings through
movement. We have various talent events throughout the year that allow our students to
explore their interests and aim for better challenges.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>SEMINAR HAll</Accordion.Header>
        <Accordion.Body>
        A spacious seminar Hall..To conduct various co-curricular activities
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey="4">
        <Accordion.Header>LABORATORIES</Accordion.Header>
        <Accordion.Body>
          <p>The College has well equipped labs:</p>
          <ol>
            <li>Physics lab</li>
            <li>Chemistry lab</li>
            <li>Biology lab</li>
          </ol>
        </Accordion.Body>
      </Accordion.Item> */}
      <Accordion.Item eventKey="5">
        <Accordion.Header>SCIENCE LABS</Accordion.Header>
        <Accordion.Body style={{textAlign:'justify'}}>
        Science lab is equipped with Biology/Chemistry & Physics equipments and materials required for students with models to explain scientific concepts. Grade 1 to 10 visit Science lab once in a week to get hands on experience on scientific concepts. The Oyster Science lab is a place for learning, experimenting , exploring and relating theory to practical learning. It gives an opportunity to young minds to satisfy their science related curiosities.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>LIBRARY</Accordion.Header>
        <Accordion.Body style={{textAlign:'justify'}}>
        Full of Knowledge Library with wide range of reference books, magazines, periodicals Audio-
Visual aids, computers aided reference and provision of Internet at library gives students an
access to e-Learning and international libraries to enhance their knowledge. Library with a
learning environment available for every students to read, study, research, and do projects
alone and in groups utilising the modern technology. Students may borrow books for a
specified time. Students are taking outmost care of borrowed books to maintain its condition. In case of loss or
damaged of any borrowed book the parents of that student take care so that there will not
be cost burden on library to refurnish it.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>PLAYGROUND/SPORTS</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:'justify'}}> The school has a spacious playground, Students in the classes I to X have 2 games/ sport periods per week and students in class I to X have 2 sports periods. Children are trained to play Football, Cricket, Dodge ball, Basketball, Throw ball and Indoor games. Students are also trained in Karate, Skating, Yoga. As a key peg in the holistic approach to education, sports is a critical part of a SSA student’s daily schedule. Every single child is encouraged to participate and strive for excellence and the focus is on learning teamwork, perseverance, hard work and achieving his/her highest potential.The PE department helps prepare the school’s inter-house sports activities, in order to synchronize it with the MSSA (Maharashtra State Sports Association) and DSO.We, at SSA, encourage students to participate in sports activities not only for enjoyment and exercise but for building essential skills. Playing a sport shapes a child in a positive way instilling a sense of self-worth. Students get an opportunity to explore a variety of sports that they wish to pursue,  both to excel in or as a hobby.The following inter-house tournaments are conducted during the year as a part of the sports curriculum:</p>
          <ul>
            <li>Athletics</li>
            <li>Cricket</li>
            <li>Running</li>
            <li>Football</li>
            <li>Cricket</li>
            <li>Athletics</li>
            <li>Yoga</li>
            <li>Ladder-Agility</li>
            <li>Hullahup</li>
            <li>Cone agility</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>TRANSPORT</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:'justify'}}>The school provides 'pay and use' facility for its students and parents. The schools’ transport division takes responsibility of the transport requirements of all concerned at school. The department ensures that the transportation services are provided to all passengers as per existing guidelines received from the competent authorities and as per the observed best practices in the industry. It is our mission to provide 'safe, comfortable, efficient and cost effective’ services to all our patrons. The parents and guardians are required to organize the transport according to their liking and convenience. However, they could also order the services through the schools' transport division by simply putting in a written request to that effect at the schools' admission office. This is a free service provided by the school for matters of convenience to parents and students; and is completely optional.The school has a fleet of safe and secure Smart Rides. The school vehicles are considered to be an integral part of infrastructure of the school and we take it in the sincerest way. The school provides fleet of buses in an excellent condition, manned and operated with highly experienced staff and well groomed assistants. The female assistants accompany the students on all routes to ensure their safety and security in all respects. The school buses come with an assurance of safety and comfort and are the perfect choice for students and operators at the same time. The safety of kids is closely monitored at all times by a trained lady attendant in the bus. The school follows all the rules and regulations related to school vehicles stringently and religiously to ensure that the school bus travel turns out to be most convenient, comfortable, and secured.Each bus is equipped with the following ultra-modern, safe and stress-free features that provide comfort, convenience, and protection to the children traveling in it.</p>
          <ul>
            <li>Lowered step for easy boarding of children</li>
            <li>Fire Extinguisher in every Bus</li>
            <li>Extra-large windows with padding and guardrails.</li>
            <li>Anti-skid flooring, guardrails, and grab handles to prevent children from slipping and getting injured.</li>
            <li>Medical Kit in every Bus</li>
            <li>Speed Governors installed in every Bus to restrict the Speed of Bus to 40 kmph.</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>CAMPUS SAFETY</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:'justify'}}>True growth of children happens only when they feel absolutely secure in their environments. A space where they can be free of stress and insecurities.That is where our campus safety programme comes into the picture. Making sure that the children have a safe and secure environment where they can rest assured about their needs is our top most priority.Our campus falls under our scrutiny with the help of the most hi-tech CCTV system which gives us minute to minute details about the activities in the whole campus. We also have security men posted at all the nodes throughout the campus. Our boardings also have the same systems running through every floor along with the addition of wardens to keep an eye on the students. Parents can rest assured about not just their child's academics but also their all round development in a safe environment.</p>
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey="10">
        <Accordion.Header>DRAWING & ARTS LAB</Accordion.Header>
        <Accordion.Body>
          <p style={{textAlign:'justify'}}>Art opens new vistas of life. It creates a new land of colors, fantasy and imagination. Art & craft gives children an opportunity to envision a land where dreams become reality and imagination becomes colors. At SSA , we educate young minds in art & craft to create, mould, chisel and shape a world of their own…..</p>
          <p>Students can choose from the below mentioned activities:</p>
          <ul>
            <li>Drawing & painting</li>
            <li>Craft works</li>
            <li>Print Making</li>
            <li>Doll Making</li>
            <li>Paper cutting & pasting</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item> */}

    </Accordion>
    </Container>
      
    </>
  )
}

export default Campusfacilities