import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Supervisordesk(props) {
  return (
    <>
     <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>SUPERVISOR DESK</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>

<Container className='pt-5'>
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>SUPERVISOR DESK</b><br/> */}
      <img width={"50%"} src='https://sindhusagaracademy.co.in/files/images/about/supervisor.png' />
    <h2>MRS. RENU C GARCHA</h2>
    <b>SUPERVISOR DESK</b><br/>
      </Col>
      <Col md={6}>
       <p style={{textAlign:'justify',paddingBottom:'5'}}>I have good fortune of incepting my carrier with {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} from this Academic year i.e. 2018-19 as an Supervisor of the school. At the very outset I observed that the students were full of inquisitiveness, curious to learn, affectionate, grounded and above all very promising and talented. The credit of this of course is entirely of our dedicated teachers and parents. As such a leaders job then does become simpler since one has to now only provide wings to take off such the sky shall be the limit.</p>
       <p style={{textAlign:'justify' ,paddingBottom:'5'}}>Albert Einstein quoted <b> “ Education is  not mere learning of facts, but the training of the minds to think”</b> . The central focus of any school is its students. My vision is that every Sindhu Sagar student should not only be knowledgeable but also be able to apply his training as a life skill wherever the situation demands also along with becoming the best in his or her chosen field. He or she must be sensible and sensitive to those not as fortunate as him / her.</p>
       <p style={{textAlign:'justify',paddingBottom:'5'}}>A deep sense of empathy with the right set of values must be inculcated such that every Alumni contributes to the well being of not only his family but to the community and the nation at large. To ensure the same the School teachers, and management has been painstakingly and devotedly working to provide the best of Teaching, Learning, activities and facilities and infrastructure leaving no stone unturned. When students develop, automatically the school will grow. While aiming for holistic development of every student the school has  expanded  its classes to include a Junior College also. As also to impart martial art training, dancing, instruments, music, sports like Basket Ball, Table Tennis, Badminton, chess, carrom, Skating and Yoga for physical, mental and creative development of every child in the school.</p>
       <p style={{textAlign:'justify' ,paddingBottom:'5'}}>I look forward to everyones continued support to the management of the school and its staff to fulfill the vision .</p>
       <p style={{textAlign:'justify' ,paddingBottom:'5'}}>May the force be with all of us.</p>
      </Col>
    </Row>
  </Container>
    
    </>
   
  )
}

export default Supervisordesk