import React, { useEffect, useState } from 'react'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaFacebook } from 'react-icons/fa'
import { Link } from "react-router-dom";
import backgroundVideo from './Untitled.mp4';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { LoginProxy } from './utils/proxy/Authproxy'

function Appstart() {

  const [college, setCollege] = useState(false);
  const [school, setSchool] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await LoginProxy();
        document.cookie = "session="+response.data.access_token;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);


  function setcollege() {
    setCollege(true);
    setSchool(false);
  }

  function setschool() {
    setCollege(false);
    setSchool(true);
  }

  if (college || school) {
    return (
      <>
        <Navigation school={school} college={college} />
      </>
    )
  } else {
    return (
      <div className="imgbg1 ">
         {/* <div style={{backgroundColor:'#f2cd66', backgroundSize: 'cover' }} > */}
      <div className='ov'>
        <section className='top-bar'>
          <Container>
            <Row>
             
              <Col md={12}>
                <ul className='top-bar-socials menu-top' >
                  <li><BsFillTelephoneFill /> 0253-257-2661</li>
                  <li><MdEmail />  nashiksindhis.mandal@gmail.com</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='text-center pt-3'>
          <Container>
            <Row >
              <Col md={2}><img width={"120px"} src='https://sindhusagaracademy.co.in/files/images/Logo.png' /></Col>
              <Col md={8}>
                <p className='text-center' style={{ fontFamily: 'Georgia, serif' }}  >NASIK SINDHI SHIKSHAN MANDAL'S</p>
                <h1  style={{ fontFamily: 'Georgia, serif' }} >SINDHU SAGAR ACADEMY</h1>
                <h3  style={{ fontFamily: 'Georgia, serif' }} >(ENGLISH MEDIUM HIGH SCHOOL) </h3>
                <h3  style={{ fontFamily: 'Georgia, serif' }} >& </h3>
                <h1  style={{ fontFamily: 'Georgia, serif' }} >R.K.KALANI JUNIOR COLLEGE</h1>
                <h3  style={{ fontFamily: 'Georgia, serif' }} >(SCIENCE & COMMERCE) </h3>

              </Col>
              <Col md={2}><img width={"120px"} src='https://sindhusagaracademy.co.in/files/images/collegebg.png' /></Col>
            </Row>
          </Container>
        </section>
        <section className='text-center'>
          <Container>
            <Row>
              {/* <div className='container-fluid headingmain'>
                <div><h2>WELCOME TO NASIK SINDHI<br></br>&nbsp;&nbsp;SHIKSHAN MANDAL'S</h2></div>
              </div> */}
                <div className='container-fluid headingmain'>
                {/* <div><Col md={2}><img width={"200px"} height={"200px"}  src='https://sindhusagaracademy.co.in/files/banners/Sindhu75-1.png' /></Col></div> */}
                <div><Col md={2}><img width={"205px"}  src='https://sindhusagaracademy.co.in/files/banners/75-yrs-logo-4.png' style={{ marginBottom: '-70px'}} /></Col></div>
              </div> 
            </Row>
          </Container>
        </section>
        <section className='text-center pt-3'>
          {/* <Container style={{paddingBottom:'10px'}}> */}
          <Container>
            <Row>
              {/* <Col md={6} style={{marginBottom:'10px'}}> */}
              <Col md={6}>
                {/* <Button onClick={setschool} variant="danger" size="lg">
                Enter School
              </Button> */}
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <button onClick={setschool} class="button-63" role="button" style={{ fontFamily: 'Georgia, serif', paddingLeft: '60px', paddingRight: '60px', paddingTop: '110px', marginBottom: '-10px'}}><span class="text" style={{fontWeight: 'bold',}}>School Tour</span></button></div> 
                  {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <button onClick={setschool} class="button-63"  role="button" style={{ fontFamily: 'Georgia, serif', backgroundImage: URL('https://sindhusagaracademy.co.in/files/banners/school-tour.jpg') }}>School Tour</button>
                </div> */}
                 

              </Col>
              {/* <Col md={6} style={{marginBottom:'10px'}}> */}
              <Col md={6} >
                {/* <Button onClick={setcollege} variant="danger" size="lg">
                Enter College
              </Button> */}
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}> 
                <button onClick={setcollege} class="button-64" role="button" style={{ fontFamily: 'Georgia, serif', paddingLeft: '60px', paddingRight: '60px', paddingTop: '110px', marginBottom: '-10px' }}><span class="text" style={{fontWeight: 'bold',}}>College Tour</span></button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      </div>
    )
  }

}

export default Appstart

