import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

function Homeslider() {
  return (
    <Carousel >
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/sindhu01.jpg"
      
        alt="First slide"
      />
      {/* <Carousel.Caption className="text-right">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    {/* <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/sindhu2.jpg"
        alt="First slide"
      />
      {/* <Carousel.Caption className="text-right">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
     {/* </Carousel.Item>  */}
    <Carousel.Item>
      <img
        className="d-block w-100"
        // src="https://sindhusagaracademy.co.in/files/images/sindhu3.jpg"
        src="https://sindhusagaracademy.co.in/files/banners/sindhu3.jpg"
        alt="Second slide"
      />

      {/* <Carousel.Caption className="text-right">
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/sindhu05.jpg"
        alt="Third slide"
      />

      {/* <Carousel.Caption className="text-right">
        <h3>Third slide label</h3>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur.
        </p>
      </Carousel.Caption> */}
    </Carousel.Item>
    {/* <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/sindhu05.JPG"
        alt="Third slide"
      /> */}

      {/* <Carousel.Caption className="text-right">
        <h3>Third slide label</h3>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur.
        </p>
      </Carousel.Caption> */}
    {/* </Carousel.Item> */}
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/college1.jpg"
        alt="First slide"
      />
      {/* <Carousel.Caption className="text-right">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/college2.jpg"
        alt="First slide"
      />
      {/* <Carousel.Caption className="text-right">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://sindhusagaracademy.co.in/files/banners/college3.jpg"
        alt="First slide"
      />
      {/* <Carousel.Caption className="text-right">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
    </Carousel.Item>
  </Carousel>
  
  )
}

export default Homeslider