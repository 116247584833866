import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap'
import { AllCollegeGallery, AllGallery } from '../../utils/proxy/Authproxy';

function Gallery(props) {
    const [data, setData] = useState([]);
    const [images,setImages] = useState([]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        if (props.school) {
          const response = await AllGallery();
          setImages(response.data.data)
        } else {
          const response = await AllCollegeGallery();
          setImages(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchGalleryData();
  }, []);
    return (
        <Container>
            <Row className='text-center'>
                <h2><b style={{ color: 'orange' }}>OUR</b> GALLERY</h2>
            </Row>
            <Row>
                  {/* Display your data */}
      {images.map((item) => (
        <Col key={item.id} md={3} className="p-2">
        <img width={"100%"} src={`https://admin.sindhusagaracademy.co.in/SindhuAPI/public/uploads/${item.image}`}/>
        </Col>
      ))}
            </Row>
            <Row>
                <div className='text-center'><Button variant="outline-warning" style={{width:200}}>Read More</Button></div>
            </Row>
        </Container>
    )
}

export default Gallery