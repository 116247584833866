import React, { useRef, useEffect } from 'react';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    video.play()
      .catch(error => {
        // Handle any potential play errors here
        console.error('Play error:', error);
      });

    return () => {
      video.pause();
    };
  }, []);

  return (
    <video
      ref={videoRef}
      src={src}
      autoPlay
      controls={false}
      loop
      style={{ width: '100%',borderRadius:20 }}
     
    />
  );
};

export default VideoPlayer;