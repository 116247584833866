import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Carousel from 'react-bootstrap/Carousel';
import VideoPlayer from '../../components/VideoPlayer';
function Donation() {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Charity</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>
            {/* <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  // src="https://sindhusagaracademy.co.in/files/images/about/donate.png"
                  src="https://sindhusagaracademy.co.in/files/banners/charity%204.jpg"
                  alt="First slide"
                />

              </Carousel.Item>


            </Carousel> */}
            <VideoPlayer src="https://sindhusagaracademy.co.in/files/banners/clip-charity.mp4" />


          </Col>
          <Col md={6} className='center'>
            <p className='text-center'>Nasik Sindhi Shikshan Mandal's, got registered under the societies in 1954, and under Bombay Public Trust in 1959...Our institution Sindhi Shikshan Mandal's is doing a very great job for uplifting the weaker section in our society.Our institution is providing free education to those students who are not able to pay for there education for that we are raising fund for this noble work.</p>
            <p className='text-center'>It’s a humble request to share amount as much as you can..!</p>
            <p className='text-center'>THANKING YOU…!</p>
            <p className='pt-5'>
            <b style={{fontSize: '25px'}} >ACCOUNT DETAILS</b><br/>
            <b style={{fontSize: '25px'}} >Account No: 080310110002153</b><br/>
            <b style={{fontSize: '25px'}} >IFSC Code:BKID0000803</b>
            </p>

<p>
  <a target='_blank' href="/80G.pdf">Click Here To View 80 G Document</a>
</p>

          </Col>

        </Row>
      </Container>
    </>
  )
}

export default Donation