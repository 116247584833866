import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AddCollegeSuggestion, AddSuggestion } from '../../utils/proxy/Authproxy';
import Swal from 'sweetalert2'

function Suggestion(props) {

  const [name,setName] = useState("");
  const [mobilenumber,setMobilenumber] = useState("");
  const [email,setEmail] = useState("");
  const [message,setMessage] = useState("");


  const addenquiry = async () => {
    console.log("sad")
    try {
      if (props.school) {
        const response = await AddSuggestion(name,mobilenumber,email,message);
      } else {
        const response = await AddCollegeSuggestion(name,mobilenumber,email,message);
      }

      Swal.fire({
        title: 'Success!',
        text: 'Suggestion Has Been Submitted ..!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>Suggestion</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row >
      <Col md={6} className='center'>
      {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/Enquirynow.png' /> */}
      <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/suggestion.jpg' />
      </Col>
      <Col md={6} className='center'>
      <Form>
        <h2>FEEDBACK/SUGGESTION</h2>
      <Form.Group className="mb-3">
        <Form.Label>Your Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Your Name" onChange={e => setName(e.target.value)}
        defaultValue={name} />
      </Form.Group>
     
    
  
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control type="text" placeholder="Enter Email" onChange={e => setEmail(e.target.value)}
        defaultValue={email} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Mobile No.</Form.Label>
        <Form.Control type="text" placeholder="Enter Mobile" onChange={e => setMobilenumber(e.target.value)}
        defaultValue={mobilenumber} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" placeholder="Enter Message" onChange={e => setMessage(e.target.value)}
        defaultValue={message} />
      </Form.Group>

  
   
      <Button variant="primary" onClick={addenquiry}>
        Submit
      </Button>
    </Form>
      </Col>
    </Row>
  </Container>
    </>
  )
}

export default Suggestion