import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';

function Testimonials(props) {
  const [currentPage, setCurrentPage] = useState(0);

  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Generate dummy data for demonstration
  const data = [
    "https://sindhusagaracademy.co.in/files/images/testimonials/1.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/2.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/3.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/4.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/5.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/6.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/7.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/8.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/9.jpg",
    "https://sindhusagaracademy.co.in/files/images/testimonials/10.jpg"];

  // Determine items to display per page
  const itemsPerPage = 4;
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Testimonials</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row >
      <Col md={6} className='center'>
      {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/careers.png' /> */}
      <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/pic-for-testimonials.jpg' />
      </Col>
      <Col md={6} className='center'>
      <h2>Testimonials</h2>
      <p style={{textAlign:'justify'}}>{props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} is a place where students thrive and experience a positive learning environment. Our students have shared their satisfaction with their time here. One student expresses, "Being a part of {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} has been life-changing. The dedicated teachers and supportive atmosphere have nurtured my personal and academic growth." Another student enthusiastically adds, "I love being a student at {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}. The engaging curriculum and diverse extracurricular activities have ignited my curiosity and allowed me to explore my passions." Our commitment to excellence and caring faculty come highly recommended. Join us at {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} and discover the transformative education we offer.</p>
      </Col>
    </Row>
    <Row>
      <h2>Parents Testimonials</h2>
       {/* Display your data */}
       {currentPageData.map((item) => (
        <Col md={3} className="p-2">
        <img width={"100%"} src={item} />
        </Col>
      ))}

      {/* Render the pagination component */}
      <ReactPaginate
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </Row>
  </Container>
    </>
  )
}

export default Testimonials