import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import VideoPlayer from '../../components/VideoPlayer';
import VideoPopup from '../../components/VideoPopup';
import { AllCollegeVideos, AllVideos } from '../../utils/proxy/Authproxy';

function Videogallery(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        if (props.school) {
          const response = await AllVideos();
        setData(response.data.data)
        } else {
          const response = await AllCollegeVideos();
        setData(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchGalleryData();
  }, []);
  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };



  // Determine items to display per page
  const itemsPerPage = 8;
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
     <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >Infrastructure</Breadcrumb.Item>
            <Breadcrumb.Item active>Video Gallery</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>

      <Container className='pt-5'>

      <Row>
         {/* Display your data */}
      {currentPageData.map((item) => (
        <Col md={3} className="p-2">
        <iframe src={`${item.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Col>
      ))}

      {/* Render the pagination component */}
      <ReactPaginate
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
      </Row>
    </Container>

    </>
  )
}

export default Videogallery