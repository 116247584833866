import Restapi from "../api/Restapi";
import {API_URL, COLLEGE_API_URL} from "../constant";


function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie(name) {
  let user = getCookie(name);

  return user;

  // if (user != "") {
  //   alert("Welcome again " + user);
  // } else {
  //   user = prompt("Please enter your name:", "");
  //   if (user != "" && user != null) {
  //     setCookie("username", user, 365);
  //   }
  // }
}


export const LoginProxy = async () => {
    let data = JSON.stringify({
        "username": "admin@mail.com",
        "password": "Samsung@77"
      });
    return await Restapi('post',API_URL+'/login',data,null);
}

export const AllGallery = async () => {
  let token = checkCookie('session');
  return await Restapi('get',API_URL+'/photos',null,token);
}

export const AllCollegeGallery = async () => {
  let token = checkCookie('session');
  return await Restapi('get',COLLEGE_API_URL+'/photos',null,token);
}

export const AllNews = async () => {
  let token = checkCookie('session');
  return await Restapi('get',API_URL+'/news',null,token);
}

export const AllCollegeNews = async () => {
  let token = checkCookie('session');
  return await Restapi('get',COLLEGE_API_URL+'/news',null,token);
}

export const AllEvents = async () => {
  let token = checkCookie('session');
  return await Restapi('get',API_URL+'/events',null,token);
}

export const AllCollegeEvents = async () => {
  let token = checkCookie('session');
  return await Restapi('get',COLLEGE_API_URL+'/events',null,token);
}

export const AllAchivements = async () => {
  let token = checkCookie('session');
  return await Restapi('get',API_URL+'/achivements',null,token);
}

export const AllCollegeAchivements = async () => {
  let token = checkCookie('session');
  return await Restapi('get',COLLEGE_API_URL+'/achivements',null,token);
}

export const AllVideos = async () => {
  let token = checkCookie('session');
  return await Restapi('get',API_URL+'/videos',null,token);
}

export const AllCollegeVideos = async () => {
  let token = checkCookie('session');
  return await Restapi('get',COLLEGE_API_URL+'/videos',null,token);
}


export const AddEnquiry = async (studentname,cityname,mobilenumber,email,message) => {
  console.log(studentname,cityname,mobilenumber,email,message)
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "studentname": studentname,
    "cityname": cityname,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',API_URL+'/enquiry',data,token);
}

export const AddCollegeEnquiry = async (studentname,cityname,mobilenumber,email,message) => {
  console.log(studentname,cityname,mobilenumber,email,message)
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "studentname": studentname,
    "cityname": cityname,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',COLLEGE_API_URL+'/enquiry',data,token);
}


export const AddSuggestion = async (name,mobilenumber,email,message) => {
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "name": name,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',API_URL+'/suggestion',data,token);
}

export const AddCollegeSuggestion = async (name,mobilenumber,email,message) => {
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "name": name,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',COLLEGE_API_URL+'/suggestion',data,token);
}


export const AddCareer = async (name,resume,mobilenumber,email,message) => {
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "name": name,
    "resume":resume,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',API_URL+'/careers',data,token);
}

export const AddCollegeCareer = async (name,resume,mobilenumber,email,message) => {
  let token = checkCookie('session');
  let data = JSON.stringify({
    "id":0,
    "name": name,
    "resume":resume,
    "mobilenumber": mobilenumber,
    "email": email,
    "message": message,
  });
  return await Restapi('post',COLLEGE_API_URL+'/careers',data,token);
}


