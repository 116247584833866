import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Ourvision() {
  return (
    <>
    <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>Ourvision</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
    <Row >
      <Col md={6} className='pt-5 pb-5'>
      {/* <img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/about/schoolvision.jpeg' /> */}
      <img width={"100%"} src='https://sindhusagaracademy.co.in/files/banners/vision%20image.jpg' />
      </Col>
      <Col md={6} className='center'>
        <h2>Our Vision</h2>
        <p style={{textAlign:'justify'}}>
        Empowering individuals with knowledge and stimulating their development. Our vision is to provide everyone a caring,  happy and a motivating environment, where children can identify and achieve to the maximum in all aspects of life like, academic creative, physical, moral and spiritual. Thus they should be able to contribute to their parents, community and to the Nation at large. With highly qualified Teachers, and well trained non-teaching staff the school focus more on academics, with various activities school helps the children to enhance their personalities. At the end our goal is that, the children should leave the school with high degree of moral values, honesty, integrity commitment and good judgement. A complement of Basic skills - linguistic,scientific, artistic, mathematical, physical and social. A desire for knowledge, an enquiring and discriminating mind. Strong self esteem and a will to excel. Tolerance and respect for others.
        </p>
      </Col>
    </Row>
  </Container>
    </>
    
  )
}

export default Ourvision