import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import { FaGlobe } from 'react-icons/fa'
import { BsFillEyeFill, BsMusicNoteList, BsTruck } from 'react-icons/bs'

import { AiFillTrophy } from 'react-icons/ai'
import { SiGoogleclassroom } from 'react-icons/si'
import { MdBiotech, MdComputer, MdSanitizer, MdOutlineSportsGymnastics, MdScience } from 'react-icons/md'
import { HiLibrary } from 'react-icons/hi'

import Card from 'react-bootstrap/Card';
import { TbDeviceCctv } from 'react-icons/tb';


function Facilities(props) {
    return (
        <Container style={{ padding: '10px' }}>
            <Row>
                {/* <Col className='center'>
                    <h3>WORLD CLASS FACILITIES</h3>
                    <p style={{ textAlign: 'justify' }}>{props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"} is devoted to excellence in teaching, learning, and research, and developing leaders in many disciplines who make a difference globally.</p>
                    <ul className='facilities'>
                        <li><FaGlobe color='orange' /> <b>International Focus</b> - our students come from across the globe</li>
                        <li><FaGlobe color='orange' /> <b>Resenditial</b> - we encourage learning and growth</li>
                        <li><FaGlobe color='orange' /> <b>People not profits</b> - education is a right not a privilege</li>
                    </ul>
                    <p></p>
                </Col> */}
                <Col className='center'>
                    {/* <h3>WORLD CLASS FACILITIES</h3> */}
                    <img width={"75%"} src='https://sindhusagaracademy.co.in/files/banners/Sindhu75-1.png' />
                </Col>
                <Col className='center'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><BsFillEyeFill color='orange' />&nbsp;Our Vision</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ textAlign: 'justify' }}>
                                    <li>Empowering individuals with knowledge and stimulating their development</li>
                                    <li>Our vision is to provide everyone a caring,happy and a motivating environment where childern can identify and acheive to the maximum in all aspects of life like,academic creative,physical ,moral and spritual.Thus they should be able to contribute to their parents,community and to the nation at large.</li>
                                    <li>With highly qualified Teachers,and well trained non-teaching staff the school focus more on academics,with various activities school helps the children to enhance their personalities</li>
                                    <li>At the end our goal is that,the childern should leave the school with high degree of moral values,honesty,integrity commitment and good judgement</li>
                                    <li>A complement of Basic skills -linuistic,scientific,artistic,mathematical,physical and social</li>
                                    <li>A desire for knowledge,an enquiring and discriminating mind</li>
                                    <li>Strong self esteem and a will to excel</li>
                                    <li>Tolerance and respect for others</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><AiFillTrophy color='orange' />&nbsp;Our Mission</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ textAlign: 'justify' }}>
                                    <li>Our mission is to develop students with active and creative minds, a sense of understanding and compassion for others, and the courage to act on their beliefs.</li>
                                    <li>Our School recognizes that each child is an individual, that all children are creative, that all children need to succeed. Therefore, our School respects the individual needs of children fosters a caring and creative environment, and emphasizes the social, emotional, physical, intellectual development of each child.</li>
                                    <li>We stress the total development of each child, spiritual, moral, intellectual, social, emotional, and physical.</li>
                                    <li>A love of nature, a sense of self-worth, responsibility, self confidence & discipline, while having fun are some of the things we inculcate in our children besides book learning.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col md={6}>
                    <h2 className='display-2'>OUR FACILITIES</h2>
                    {/* <p className='lead'>Empowering individuals with knowledge and stimulating their development</p> */}
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <SiGoogleclassroom />
                        <Card.Body>Smart Classes</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <BsTruck />
                        <Card.Body>Transport</Card.Body>
                    </Card>
                </Col>
               

            </Row>
            <Row className='pt-5'>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <MdScience  />
                        <Card.Body>Chemistry Lab</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <MdBiotech />
                        <Card.Body>Bio Lab</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <HiLibrary />
                        <Card.Body>Library</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <MdSanitizer />
                        <Card.Body>Physics Lab</Card.Body>
                    </Card>
                </Col>
                

            </Row>
            <Row className='pt-5'>
            <Col md={3}>
                    <Card className='card-facilites'>
                        < MdOutlineSportsGymnastics />
                        <Card.Body>Playground</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <TbDeviceCctv />
                        <Card.Body>CCTV Survelience</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <MdComputer />
                        <Card.Body>IT Lab</Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='card-facilites'>
                        <BsMusicNoteList />
                        <Card.Body>Music & Dance</Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Facilities