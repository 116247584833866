import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Introtext() {
  return (
    <Container>
      <Row className='text-center p-5'>
        <Col><h2>Inspired By Excellence & Innovation</h2> <p>We Offer A Wide Range Of High Quality Teaching And Extra-Curricular Activities.</p></Col>
      </Row>
    </Container>
  )
}

export default Introtext