import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Completedevent from './Completedevent';
import Upcommingevent from './Upcommingevent';
import News from './News';

function Eventspage(props) {

  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>

      <Container className='pt-5'>

      <Row>
      <Tabs
      defaultActiveKey="news"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="news" title="News">
        <News school={props.school}/>
      </Tab>
      <Tab eventKey="profile" title="Upcomming Events">
        <Upcommingevent school={props.school}/>
      </Tab>
      <Tab eventKey="home" title="Completed Events">
        <Completedevent school={props.school}/>
      </Tab>
     
    </Tabs>
      </Row>
    </Container>

    </>
  )
}

export default Eventspage