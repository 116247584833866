import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Homeslider from './Homeslider'
import Introtext from './Introtext'
import Aboutinstitute from './Aboutinstitute'
import Facilities from './Facilities'
import { Helmet } from 'react-helmet';
import Gallery from './Gallery'
import News from './News'
import Form from 'react-bootstrap/Form';
import { AddEnquiry } from '../../utils/proxy/Authproxy';
import Swal from 'sweetalert2'

function Home(props) {

  const [studentname,setStudentname] = useState("");
  const [cityname,setCityname] = useState("");
  const [mobilenumber,setMobilenumber] = useState("");
  const [email,setEmail] = useState("");
  const [message,setMessage] = useState("");

  console.log(props.school)

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true); // Set the showModal state to true when the component loads
  }, []);

  const handleClose = () => setShowModal(false);

  const addenquiry = async () => {
    console.log("sad")
    try {
      const response = await AddEnquiry(studentname,cityname,mobilenumber,email,message);
      setShowModal(false);
      Swal.fire({
        title: 'Success!',
        text: 'Enquiry Has Been Submitted ..!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <>
      <Helmet>
        <title>{props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}
        </title>
        <meta name="description" content="With more than 75,000+ students enrolled across 25+ cities, Sindhu Sagar Acadmey are among the top award-winning CBSE schools in India, offering CBSE and ICSE curriculum." />
        <meta name="keywords" content="sindhu,sagar,academy,international,school" />
        <link rel="canonical" href="https://sindhusagaracademy.co.in/" />
      </Helmet>

      <section>
        <Homeslider />
      </section>
      <section>
        <Introtext />
      </section>
      <section>
        <Aboutinstitute school={props.school} />
      </section>

      <section style={{ backgroundColor: "#F2F2F2" }}>
        <Facilities />
      </section>

      <section className='pt-5 pb-5'>
        <Gallery school={props.school} />
      </section>
      <section className='pt-5 pb-5'>
        <News school={props.school} />
      </section>

      <Modal show={showModal} onHide={handleClose} closeButton>
        <Modal.Header closeButton>
          <Modal.Title>ADMISSION ENQUIRY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Student Name</Form.Label>
              <Form.Control type="text" onChange={e => setStudentname(e.target.value)}
        defaultValue={studentname} placeholder="Enter Student Name" />
            </Form.Group>
            <Form.Group className="mb-3">
        <Form.Label>Class Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Class Name" />
      </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City Name</Form.Label>
              <Form.Control type="text" onChange={e => setCityname(e.target.value)}
        defaultValue={cityname} placeholder="Enter City Name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" onChange={e => setMobilenumber(e.target.value)}
        defaultValue={mobilenumber} placeholder="Enter Mobile Number" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" onChange={e => setEmail(e.target.value)}
        defaultValue={email} placeholder="Enter Email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" onChange={e => setMessage(e.target.value)}
        defaultValue={message} placeholder="Enter Message" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addenquiry} type="submit">
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Home