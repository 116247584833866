import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';
import ReactPaginate from 'react-paginate';
import { AllAchivements, AllCollegeAchivements } from '../../utils/proxy/Authproxy';

function Achievements(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        if (props.school) {
          const response = await AllAchivements();
        setData(response.data.data)
        } else {
          const response = await AllCollegeAchivements();
        setData(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchGalleryData();
  }, []);

   // Handle page change event
   const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  


  // Determine items to display per page
  const itemsPerPage = 8;
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    <>
    <section className='bg-light p-1'>
      <Container>
        <Breadcrumb >
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item >Infrastructure</Breadcrumb.Item>
          <Breadcrumb.Item active>Achievements</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>

    <Container className='pt-5 pb-5'>
    
    <Row>
     <Col md={8}>
     <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="https://sindhusagaracademy.co.in/files/images/about/award1.jpeg"
          src="https://sindhusagaracademy.co.in/files/banners/achieve1.jpeg"
          alt="First slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
           // src="https://sindhusagaracademy.co.in/files/images/about/award2.jpeg"
          src="https://sindhusagaracademy.co.in/files/banners/achieve2.jpg"
           alt="Second slide"
         /> 

        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="https://sindhusagaracademy.co.in/files/images/about/award2.jpeg"
          src="https://sindhusagaracademy.co.in/files/banners/achieve3.jpeg"
          alt="Second slide"
        />

        
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          // src="https://sindhusagaracademy.co.in/files/images/about/award2.jpeg"
          src="https://sindhusagaracademy.co.in/files/banners/achieve6.png"
          alt="Second slide"
        />

        
      </Carousel.Item> */}
    
    </Carousel>
     </Col>
     <Col md={4} className='center'>
    <h2>Our Achievements</h2>
      <p>Achievement is the result of hard work, dedication, and perseverance. It represents our potential realized and inspires us to aim higher. Each achievement is a stepping stone towards success, reminding us that anything is possible with the right mindset.</p>
     </Col>
     
    </Row>
    <hr/>
    <Row>
         {/* Display your data */}
      {currentPageData.map((item) => (
        <Col key={item.id} md={3} className="p-2">
        <img width={"100%"} src={`https://${props.school ? 'admin' : 'college'}.sindhusagaracademy.co.in/SindhuAPI/public/uploads/${item.achivement}`}/>
        </Col>
      ))}
       
      </Row>
      {/* <ReactPaginate
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      /> */}
  </Container>

  </>
  )
}

export default Achievements