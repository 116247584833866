import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Aboutcollege() {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item >About Us</Breadcrumb.Item>
            <Breadcrumb.Item active>About College</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>
          <img width={"100%"} src='https://sindhusagaracademy.co.in/admin/images/JFyNAFoD4tl9FZcLF4ciMCDFMNHsvVQPTHQZCmLt.JPG' />
          </Col>
          <Col md={6}>
            <h2>About College</h2>
            <p style={{textAlign:'justify'}}>
            Welcome to R. K.Kalani Junior College a highly progressive and enterprising college which runs 11th and 12th std (Science and Commerce) located in the heart of the city, from where students can get easy transport facility, is pledged to provide quality education with great emphasis on traditional values. A junior college is a post-secondary educational institution offering vocational training designed to prepare students for either skilled trades and technical occupations and support roles in professions such as engineering, accountancy, business administration, nursing, medicine, architecture, etc for additional education at another college with more advanced academic material. Students typically attend junior colleges for 2 years. The college is well managed by a team of dedicated Management members and attempt is made to provide all possible opportunities of the best education and development of an all round personality of the student to meet the challenges of a competitive future. R.K. Kalani Junior College established in the year 2018 maintains a reputation for its high academic standards, personal care and attention to the all round development of talents and personality of each student The College employs well-qualified staff to meet the academic and extra-curricular needs of the students. Over the short span of 5 years, the college has steadily grown in size, in educational scope and in reputation until it is now counted among the better-known college in Nashik.
            </p>
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default Aboutcollege