import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import VideoPlayer from '../../components/VideoPlayer';
import Accordion from 'react-bootstrap/Accordion';

function Faq(props) {
  return (
    <>
      <section className='bg-light p-1'>
        <Container>
          <Breadcrumb >
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>FAQ's</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md={6} className='center'>


            {/* <VideoPlayer src="https://sindhusagaracademy.co.in/files/images/about/Designer.mp4" /> */}
            <VideoPlayer src="https://sindhusagaracademy.co.in/files/banners/faq.mp4" />


          </Col>
          <Col md={6} className='center'>
            <h2>Frequently Asked Questions.</h2>
            <p style={{textAlign:'justify'}}>
              Welcome to {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}! Here are some common questions and answers for your convenience. What programs do we offer? We provide a variety of academic programs. How can I apply for admission? The application process involves Offline FOrm Filling. Are scholarships or financial aid available? Yes, we offer scholarships and financial aid programs. Contact us for more information. How can I reach the school administration? You can contact our administration office at nashiksindhis.mandal@gmail.com. What extracurricular activities are available? We offer a range of activities and clubs to suit different interests. Visit our Activities page for details. Where can I find the academic calendar? The academic calendar is available on our website's Calendar section. How can parents access their child's grades and progress? We provide an online portal for parents to access grades and progress reports. Contact the school for login details. What safety measures are in place? We prioritize student safety with protocols, surveillance, and trained staff. Are transportation services available? Yes, transportation services are available for eligible students. Contact our transportation department for details. Can I visit the campus? Yes, schedule a campus tour by contacting our admissions office. If you have further questions, feel free to reach out to us.
            </p>
          </Col>

        </Row>

        <Row>
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How can I apply ?</Accordion.Header>
                <Accordion.Body>
                  You can call or visit our office which is stated in our contact details
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I Pay Fees in Installment ?</Accordion.Header>
                <Accordion.Body>
                  Yes, You can divide it in Installment
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What is the admission procedure?</Accordion.Header>
                <Accordion.Body>
                  In 4 easy steps, we assist you to entrust us your future explorers, and knowledge creators.
                  Ouradmissionprocessinvolves,
                  <ul>
                    <li>You visit the School and answer a survey to observe our learning environment, speak with our officers, get your queries answered</li>
                    <li>You submit an application form for curriculum/grade to which you wish to admit your child giving all relevant details about the child.</li>
                    <li>We interview, interact and assess your child’s learning abilities on a given date before shortlisting for admission.</li>
                    <li>You pay the registration fees and the admission is complete</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>What are my child’s school timings?</Accordion.Header>
                <Accordion.Body>
                  <p>Playgroup: 9:00 Am - 1200Pm</p>
                  <p>Nursery - Sr KG - 9:00 am to 12:00 pm</p>
                  <p>Grades 1-10: 8:15 am to 2:45 pm</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>What facilities does your school offer?</Accordion.Header>
                <Accordion.Body>
                  <p>The infrastructure of the school aids and fosters the process of learning -</p>
                 <ul>
                  <li>spacious and ventilated classrooms.</li>
                  <li>Technologically well-equipped classrooms with smart boards and computers which are easily accessible to students with internet browsing.</li>
                  <li>Well-equipped Visual Art Room, Music and Dance Studio.</li>
                  <li>Knowledge enriched Resource and Research Centre.</li>
                  <li>Science, Biology, Physics and ICT Labs.</li>
                  <li>State-of-the-art Audio-Visual Auditorium.</li>
                  <li>Canteen</li>
                  <li>Play area.</li>
                  <li>Multipurpose area for sports</li>
                 </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How many students will you have in every class and how many divisions do youplan to have?</Accordion.Header>
                <Accordion.Body>
                  <p>We maintain 20 children per class till pre-primary and 50 to 60 in primary and secondary. We have two divisions in each grade.</p>
                 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Does the school provide a transport facility?</Accordion.Header>
                <Accordion.Body>
                  <p>Yes, school provides the transport facility. The transportation expense is excluded in the annual fee as it depends on the routes. Please have a look at the bus routes before deciding on admission.Kindly go through our bus routes once you fill the enquiry form.</p>
                 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Will there be an increase in fees?</Accordion.Header>
                <Accordion.Body>
                  <p>Yearly, the fees within the same program (Early years, PYP and Secondary) are likely to increase as per government norms (10-15%). In case when a student shifts from early years to primary years, and then from primary to senior school, there are slab changes, which are as specified in the current fee structure.</p>
                 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>Does your school offer scholarships?</Accordion.Header>
                <Accordion.Body>
                  <p>Guidance for scholarship exams and competitive exams are provided by the mentors.</p>
                 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Faq