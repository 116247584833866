import React from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebook, FaPhone } from 'react-icons/fa'
import Home from '../pages/Home/Home';
import Aboutsanstha from '../pages/About/Aboutsanstha';
import Aboutschool from '../pages/About/Aboutschool';
import Chairman from '../pages/About/Members/Chairman';
import Secretary from '../pages/About/Members/Secretary';
import Vicepresident from '../pages/About/Members/Vicepresident';
import Treasurer from '../pages/About/Members/Treasurer';
import Jointsecretary from '../pages/About/Members/Jointsecretary';
import Principaldesk from '../pages/About/Members/Principaldesk';
import Supervisordesk from '../pages/About/Members/Supervisordesk';
import Enquiry from '../pages/Contact/Enquiry';
import Photogallery from '../pages/Infrastructure/Photogallery';
import Videogallery from '../pages/Infrastructure/Videogallery';
import Achievements from '../pages/Infrastructure/Achievements';
import Campusfacilities from '../pages/Infrastructure/Campusfacilities';
import Suggestion from '../pages/Contact/Suggestion';
import Admission from '../pages/Admission/Admission';
import Faq from '../pages/Faq/Faq';
import Postquery from '../pages/Faq/Postquery';
import Syllabus from '../pages/Studentcorner/Syllabus';
import Result from '../pages/Studentcorner/Result';
import Sports from '../pages/Studentcorner/Sports';
import Helpline from '../pages/Studentcorner/Helpline';
import Transport from '../pages/Transport/Transport';
import Donation from '../pages/Donation/Donation';
import Careers from '../pages/Careers/Careers';
import Eventspage from '../pages/News/Eventspage';
import Testimonials from '../pages/Testimonials/Testimonials';
import Ourvision from '../pages/About/Ourvision';
import Ourmission from '../pages/About/Ourmission';
import Prospectus from '../pages/About/prospectus';
import {  AiTwotoneMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import Aboutcollege from '../pages/About/Aboutcollege';
import CollegeFaq from '../pages/Faq/CollegeFaq';
import Footer from './Footer';


function Blogs() {
  return (
    <h1>Blogs</h1>
  )
}
function Contact() {
  return (
    <h1>Contact</h1>
  )
}

function NoPage() {
  return (
    <h1>No pAge</h1>
  )
}

function Navigation(props) {
  return (
    <BrowserRouter >
    <div className='sticky-top'>
      <section className='top-bar'>
        <Container fluid>
          <Row>
            <Col md={6}>
              <ul className='top-bar-socials menu-top'>
            
                <li ><BsFillTelephoneFill /> 0253-257-2661</li>
                <li><AiTwotoneMail/> nashiksindhis.mandal@gmail.com</li>
              </ul>
            </Col>
            <Col md={6}>
              <ul className='top-bar-socials menu-top' style={{ float: 'right' }}>
              {props.school ? <li className='top-menu'><Link to="transport">Transport</Link></li> : "" }
               <li className='top-menu'><Link to="donation">Charity</Link></li>
               <li className='top-menu'><Link to="careers">Careers</Link></li>
               <li className='top-menu'><Link to="news">News & Events</Link></li>
               <li className='top-menu'><Link to="testimonials">Testimonials</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <Navbar sticky="top" style={{backgroundColor: "#fff"}} expand="lg" variant="light">
        <Container>
          <Navbar.Brand to="/">
            <Row>
     
              
            {props.school ? <Col><img width={"80px"} src='https://sindhusagaracademy.co.in/files/images/Logo.png' /></Col> : <Col><img width={"80px"} src='https://sindhusagaracademy.co.in/files/images/collegebg.png' /></Col>}
            {props.school ? <Col><span style={{ fontSize: "15px"}}><b>SINDHU SAGAR ACADEMY </b><br />(ENGLISH MEDIUM HIGH SCHOOL)</span></Col> : <Col><span style={{ fontSize: "15px" }}><b>R.K.KALANI JUNIOR COLLEGE </b><br />(SCIENCE & COMMERCE)</span></Col>}
              
            </Row>


          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'flex-end' }}>
            <Nav>
              <Link className='nav-link' to="/">Home</Link>
              <NavDropdown title="About Us" id="basic-nav-dropdown">
                <NavDropdown title="Our Members" id="basic-nav-dropdown" className='submenu'>
                  <Link className='dropdown-item' to="chairman">President</Link>
                  <Link className='dropdown-item' to="secretary">Secretary</Link>
                  <Link className='dropdown-item' to="vicepresedent">Vice President</Link>
                  <Link className='dropdown-item' to="treasurer">Treasurer</Link>
                  <Link className='dropdown-item' to="jointsecretary">Joint Secretary</Link>
                  <Link className='dropdown-item' to="principaldesk">Principal's Desk</Link>
                  <Link className='dropdown-item' to="supervisordesk">Supervisor's Desk</Link>
                </NavDropdown>
                <Link className='dropdown-item' to="aboutsanstha">About Sanstha</Link>
                {props.school ? <Link className='dropdown-item' to="aboutschool">About School</Link>: <Link className='dropdown-item' to="aboutcollege">About College</Link>}
                
                <Link className='dropdown-item' to="our-vision">Our Vision</Link>
                <Link className='dropdown-item' to="our-mission">Our Mission</Link>
                <Link className='dropdown-item' to="prospectus">Prospectus</Link>
              </NavDropdown>
         
             
  
              <NavDropdown title="Infrastructure" id="basic-nav-dropdown">
                <Link className='dropdown-item' to="photogallery">Photo Gallery</Link>
                <Link className='dropdown-item' to="videogallery">Video Gallery</Link>
                <Link className='dropdown-item' to="campusfacilities">Campus Facilities</Link>
                <Link className='dropdown-item' to="achivements">Achievements</Link>
               
              </NavDropdown>
              <NavDropdown title="Admission" id="basic-nav-dropdown">
                <Link className='dropdown-item' to="admission">Admission Process</Link>

              </NavDropdown>
              <NavDropdown title="Contact Us" id="basic-nav-dropdown">
                <Link className='dropdown-item' to="enquiry">Enquiry</Link>
                <Link className='dropdown-item' to="suggestion">Suggestion</Link>
               
                
              </NavDropdown>
              
              {props.school ? <Link className='nav-link' to="faq">FAQ</Link> :
          <Link className='nav-link' to="collegefaq">FAQ</Link>}
          {/* <Link className='dropdown-item' to="postquery">Post Query</Link> */}
         
        
              {/* <NavDropdown title="Student Corner" id="basic-nav-dropdown">
                <Link className='dropdown-item' to="syllabus">Syllabus</Link>
                <Link className='dropdown-item' to="result">Result (SSC/HSC)</Link>
                <Link className='dropdown-item' to="sports">Sports & Activities</Link>
                <Link className='dropdown-item' to="helpline">Student Helpline & Complaint</Link>
              
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
      <Routes>
        <Route index element={<Home  school={props.school}/>} />
        <Route path='aboutsanstha' element={<Aboutsanstha school={props.school}/>}/>
        <Route path='aboutschool' element={<Aboutschool school={props.school}/>}/>
        <Route path='aboutcollege' element={<Aboutcollege school={props.school}/>}/>
        <Route path='chairman' element={<Chairman school={props.school}/>}/>
        <Route path='secretary' element={<Secretary school={props.school}/>}/>
        <Route path='vicepresedent' element={<Vicepresident school={props.school}/>}/>
        <Route path='treasurer' element={<Treasurer school={props.school}/>}/>
        <Route path='jointsecretary' element={<Jointsecretary school={props.school}/>}/>
        <Route path='principaldesk' element={<Principaldesk school={props.school}/>}/>
        <Route path='supervisordesk' element={<Supervisordesk school={props.school}/>}/>
        <Route path='our-vision' element={<Ourvision school={props.school}/>}/>
        <Route path='our-mission' element={<Ourmission school={props.school}/>}/>
        <Route path='prospectus' element={<Prospectus school={props.school}/>}/>
        <Route path='enquiry' element={<Enquiry school={props.school}/>}/>
        <Route path='suggestion' element={<Suggestion school={props.school}/>}/>
        <Route path='photogallery' element={<Photogallery school={props.school}/>}/>
        <Route path='videogallery' element={<Videogallery school={props.school}/>}/>
        <Route path='achivements' element={<Achievements school={props.school}/>}/>
        <Route path='campusfacilities' element={<Campusfacilities school={props.school}/>}/>
        <Route path='admission' element={<Admission school={props.school}/>}/>
        <Route path='faq' element={<Faq school={props.school}/>}/>
        <Route path='collegefaq' element={<CollegeFaq school={props.school}/>}/>
        <Route path='postquery' element={<Postquery school={props.school}/>}/>
        <Route path='syllabus' element={<Syllabus school={props.school}/>}/>
        <Route path='result' element={<Result school={props.school}/>}/>
        <Route path='sports' element={<Sports school={props.school}/>}/>
        <Route path='helpline' element={<Helpline school={props.school}/>}/>
        <Route path='transport' element={<Transport school={props.school}/>}/>
        <Route path='donation' element={<Donation school={props.school}/>}/>
        <Route path='careers' element={<Careers school={props.school}/>}/>
        <Route path='news' element={<Eventspage school={props.school}/>}/>
        <Route path='testimonials' element={<Testimonials school={props.school}/>}/>
        <Route path="blogs" element={<Blogs school={props.school} />} />
        <Route path="contact" element={<Contact school={props.school} />} />
        <Route path="*" element={<NoPage school={props.school} />} />
      </Routes>
      <section style={{ backgroundColor: '#531E1E' }}>
          <Footer school={props.school} college={props.college} />
        </section>
    </BrowserRouter>
    
  )
}

export default Navigation