import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Carousel from 'react-bootstrap/Carousel';
import VideoPlayer from '../../components/VideoPlayer';

function Transport() {
  return (
    <>
    <section className='bg-light p-1'>
      <Container>
        <Breadcrumb >
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item >Infrastructure</Breadcrumb.Item>
          <Breadcrumb.Item active>Transport</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>

    <Container className="pt-5 pb-5">
    <Row>
     <Col md={6} className='center'>
     {/* <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100" */}
          {/* // src="https://sindhusagaracademy.co.in/files/images/about/DSC_0630.JPG" */}
          {/* src="https://sindhusagaracademy.co.in/files/banners/Transport-1.jpg"
          alt="First slide"
        />
       
      </Carousel.Item>
   
    
    </Carousel> */}
    <VideoPlayer src="https://sindhusagaracademy.co.in/files/banners/clip-for-transport.mp4" />

     </Col>
     <Col md={6} className='center'>
      <p>The school provides Bus facility for its students and parents. The schools’ transport division takes responsibility of the transport requirements of all concerned at school. The department ensures that the transportation services are provided to all passengers as per existing guidelines received from the competent authorities and as per the observed best practices in the industry. It is our mission to provide 'safe, comfortable, efficient and cost effective’ services to all our patrons. The parents and guardians are required to organize the transport according to their liking and convenience. However, they could also order the services through the schools' transport division by simply putting in a written request to that effect at the schools' admission office. This is a free service provided by the school for matters of convenience to parents and students; and is completely optional.The school has a fleet of safe and secure Smart Rides. The school vehicles are considered to be an integral part of infrastructure of the school and we take it in the sincerest way. The school provides fleet of buses in an excellent condition, manned and operated with highly experienced staff and well groomed assistants. The female assistants accompany the students on all routes to ensure their safety and security in all respects. The school buses come with an assurance of safety and comfort and are the perfect choice for students and operators at the same time. The safety of kids is closely monitored at all times by a trained lady attendant in the bus. The school follows all the rules and regulations related to school vehicles stringently and religiously to ensure that the school bus travel turns out to be most convenient, comfortable, and secured.Each bus is equipped with the following ultra-modern, safe and stress-free features that provide comfort, convenience, and protection to the children traveling in it..</p>
     </Col>
     
    </Row>
    <hr/>
<Row>
  <Col md={4} className='p-3'>
  •	Lowered step for easy boarding of children
  </Col>
  <Col md={4} className='p-3'>
  •	Fire Extinguisher in every Bus
  </Col>
  <Col md={4} className='p-3'>
  •	Extra-large windows with padding and guardrails.
  </Col>
  <Col md={4} className='p-3'>
  •	Anti-skid flooring, guardrails, and grab handles to prevent children from slipping and getting injured.
  </Col>
  <Col md={4} className='p-3'>
  •	Medical Kit in every Bus
  </Col>
  <Col md={4} className='p-3'>
  •	Speed Governors installed in every Bus to restrict the Speed of Bus to 40 kmph.
  </Col>
</Row>
<hr/>
<h2>BUS DETAILS</h2>
      <Row>
        <Col>
        <Table hover>
      <thead>
        <tr>
          <th>Bus Driver Name</th>
          <th>Bus No.</th>
          <th>Route</th>
          <th>Contact No.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>BHAUSAHEB RAGHO SHEWALE</td>
          <td>BUS NO. 1</td>
          <td>Link Road, Mhada Colony , Satpur</td>
          <td>9270021331</td>
          
        </tr>
        <tr>
          <td>CHANCHAL JUNAGADE</td>
          <td>BUS NO. 2</td>
          <td>Ashoknagar,Shramiknagar</td>
          <td>9067109034</td>
          
        </tr>
        <tr>
          <td>RAJU WATHORE</td>
          <td>BUS NO. 3</td>
          <td>DGP Nagar 2, Ambad Datir Nagar</td>
          <td>9689312135</td>
        </tr>
        <tr>
          <td>AKASH GANGURDE</td>
          <td>BUS NO. 4</td>
          <td>Makhmalabad Road, Dindori Road </td>
          <td>9096378243</td>
            </tr>
        <tr>
          <td>YASHWANT SHEWALE</td>
          <td>BUS NO. 5</td>
          <td>Khutwad Nagar,Sanjiv Nagar</td>
          <td>9970189663</td>
          
        </tr>
        <tr>
          <td>RAJENDRA BHANSINGH PATIL</td>
          <td>BUS NO. 6</td>
          <td>Hirawadi, Rasbihari Road	</td>
          <td>8605271454</td>
          
        </tr>
        <tr>
          <td>MUNNA PARDESHI</td>
          <td>BUS NO. 7</td>
          <td>Shivaji Nagar, Motiwala College Road, Gangapur Road </td>
          <td>8308562961</td>
          
        </tr>
        {/* <tr>
          <td>RAHUL GAJENDRA PATIL</td>
          <td>SHIVAJI NAGAR,SATPUR,GANGAPUR RD</td>
          <td>9518925369</td>
          <td>MH.15. JC.0735</td>
        </tr>
        <tr>
          <td>DHANRAJ MURLIDHAR MAHALE</td>
          <td>MAKHAMALABAD MHASRUL</td>
          <td>9767592018</td>
          <td>MH.15 GV.3556</td>
        </tr> */}
      
      </tbody>
    </Table>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Transport