import React from 'react'
import { FaFacebook } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Footer(props) {

  return (
    <div className="container">
      <footer className="py-5">
        <div className="row">
          <div className="col-6 col-md-2 mb-3">
            {props.school ? <><img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/Logo.png' />
            <span style={{ fontSize: "10px", color: '#fff', textAlign: 'center' }}><b>SINDHU SAGAR ACADEMY </b><br />(ENGLISH MEDIUM HIGH SCHOOL)</span></> : <><img width={"100%"} src='https://sindhusagaracademy.co.in/files/images/collegebg.png' />
            <span style={{ fontSize: "10px", color: '#fff', textAlign: 'center' }}><b>R.K.KALANI JUNIOR COLLEGE </b><br />(SCEINCE & COMMERCE)</span></>}
          </div>
          <div className="col-6 col-md-2 mb-3 text-white">
            <h5>Quick Links</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><Link to="transport" className="nav-link p-0 text-white">Transport</Link ></li>
              <li className="nav-item mb-2"><Link to="donation" className="nav-link p-0 text-white">Charity</Link></li>
              <li className="nav-item mb-2"><Link to="careers" className="nav-link p-0 text-white">Careers</Link></li>
              <li className="nav-item mb-2"><Link to="news" className="nav-link p-0 text-white">News & Events</Link></li>
              <li className="nav-item mb-2"><Link to="testimonials" className="nav-link p-0 text-white">Testimonials</Link></li>
            </ul>
          </div>
          <div className="col-6 col-md-2 mb-3 text-white">
            <h5>Quick Links</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><Link to="aboutsanstha" className="nav-link p-0 text-white">About Sanstha</Link></li>
              <li className="nav-item mb-2"><Link to="aboutschool" className="nav-link p-0 text-white">About School</Link></li>
              <li className="nav-item mb-2"><Link to="enquiry" className="nav-link p-0 text-white">Enquiry</Link></li>
              <li className="nav-item mb-2"><Link to="admission" className="nav-link p-0 text-white">Admission Process</Link></li>
            </ul>
          </div>
          <div className="col-md-3 text-white">
            <h5>Address</h5>
            <p> {props.school ? "Sindhu Sagar Academy" : "R. K. Kalani Junior College"}, Sharanpur Road, Near Kabra Showroom, Old Pandit Colony, Nashik</p>
            <p>Phone No : 0253 2572661</p>
            <p>nashiksindhis.mandal@gmail.com</p>
          </div>
          <div className="col-md-3 text-white">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3749.138310184872!2d73.77708!3d20.002709!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba0d4565a55%3A0x8dc149a475b9c8e0!2sSindhu%20Sagar%20Academy!5e0!3m2!1sen!2sin!4v1684136446796!5m2!1sen!2sin" width="100%"  height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top text-white">
          <p>© 1949 Nasik Sindhi Shikshan Mandal. All Rights Reserved.  Designed and Developed by R3 Systems India Private Limited. </p>
          {/* <p >
          Designed and Developed by R3 Systems India Private Limited.
    </p> */}
          <ul className="list-unstyled d-flex">
            <li className="ms-3"><a className="link-dark" href="https://www.facebook.com/sindhu.sagar.9235?mibextid=ZbWKwL"><FaFacebook style={{ color: '#fff' }} /></a></li>
            
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Footer