import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Secretary() {
  return (
    <>
                <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>SECRETARY</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>
<Container className='pt-5'>
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>SECRETARY</b><br/> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/admin/images/BLgChr9h5utB5oNnsXvPql4W8zcYoDfAYrLrBrYN.JPG' /> */}
      <img width={"50%"} src='https://sindhusagaracademy.co.in/files/banners/HIRANI%20SIR.jpg' />
    <h2>MR. ARJUN KHANCHAND HIRANI</h2>
    <b>SECRETARY</b><br/>
      </Col>
      <Col md={6}>
        <ul style={{textAlign:'justify', listStyleType:'none'}}>
          <li style={{paddingBottom:5}} > <b>Education is the key</b> to transforming the destiny of any nation and it is indeed my honour and privilege to serve as the Honorary Secretary of the Board of Trustees of Nasik Sindhi Shikshan Mandal's. It is my mission to ensure a smooth journey of one of the most prestigious schools in Nasik.</li>
          <li style={{paddingBottom:5}}>It is my continued endeavor to improve upon the quality and scale of infrastructure of the school. Existing facilities are constantly upgraded to ensure that our students have access to the latest equipment. Smart technology has been integrated in classrooms to boost student achievement. There are absolutely no compromises on the quality of education and efforts are actively undertaken to retain and select qualified and experienced staff. The skills of teaching faculty are upgraded with workshops held at regular intervals. Distinguished personalities are invited for seminars to inspire and enlighten the students. The Management and Staff of the school is always ready to walk that extra mile as we believe that a student passing out of the school should be adequately equipped to face the challenges of the world.</li>
          <li style={{paddingBottom:5}}>Our school value system stresses on making every child morally strong and enabling them to become a better citizen of tomorrow along with giving a multi facet exposure to the students. Quintessential values are instilled in them.</li>
          <li style={{paddingBottom:5}}>The school is inching every moment towards greater heights in the fields of academics, sports and extra curricular activities and also infrastructure.</li>
          <li style={{paddingBottom:5}}>All my efforts are directed at grooming our students into creative thinkers, effective problem-solvers and sensitive human beings. We will not spare any effort at providing them with the best learning environment to realize their immense potential.</li>
        
        </ul>
      </Col>
    </Row>
  </Container>
    </>
  
  )
}

export default Secretary