import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Principaldesk() {
  return (
    <>
            <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>PRINCIPAL’S  DESK</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>
<Container className='pt-5'>
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>PRINCIPAL’S  DESK</b><br/> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/images/about/member-placeholder.png' /> */}
      <img width={"50%"} src='https://sindhusagaracademy.co.in/files/banners/principal.jpg' />
      <h2>MRS. SIMRAN  ROOPAM  MAKHIJANI</h2>
      <p>M.COM , M.A , B.Ed , D.Ed</p>
      <b>PRINCIPAL’S  DESK</b><br/>
      </Col>
      <Col md={6}>
        <ul style={{textAlign:'justify', listStyleType:'none'}}>
          <li style={{paddingBottom:5}}>It gives me immense pleasure and joy that I have become a part of this old institution. Teaching is a noble and exemplary job, it touch the lives of the innocent young minds. To accomplish this one needs to be self discipline, dedicated, accountable and responsible.</li>
          <li style={{paddingBottom:5}}>I would like to say that progress and progress only is our motto. We need to grow and progress every day, every week, every month, and every year. We need quality of the institution, quality of an individual and quality of life as a whole. Let us rise higher and higher, sky is our limit and shall keep the lamp of knowledge burning to achieve the vision.</li>
         
        </ul>
      </Col>
    </Row>
  </Container>
    </>
    
  )
}

export default Principaldesk